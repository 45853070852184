/* Hide arrows in number input for Chrome, Safari, Edge, and Opera */
.daily-input::-webkit-outer-spin-button,
.daily-input::-webkit-inner-spin-button,
.monthly-input::-webkit-outer-spin-button,
.monthly-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide arrows in number input for Firefox */
.monthly-input,
.daily-input {
    -moz-appearance: textfield;
}


.target-funnel-container {
    margin: auto;
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.accordion-item {
    /* background: white; */
    border-radius: 5px;
    border: 1px solid #ddd;
    overflow: hidden;
}

.accordion-header {
    width: 100%;
    /* background: white; */
    border: none;
    text-align: left;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-content {
    padding: 15px;
    /* background: #f9f9f9; */
    border-top: 1px solid #ddd;
}

.new-target-btn {
    background-color: #c00;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    /* margin-bottom: 10px; */
    height: 40px;
}


.target-box {
    background: white;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* width: 30%; */
}

.target-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.target-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.color-picker,
.monthly-target {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.color-input {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #ccc;
    cursor: pointer;
    overflow: hidden;
    -webkit-appearance: none;
    /* Remove default styles */
    appearance: none;
}

.monthly-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100px;
    text-align: center;
}

.target-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    /* 
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    gap: 8px; */

}

.delete-btn {
    background: none;
    color: #c00;
    border: none;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: Inter;
}

.add-btn {
    background: #ccc;
    color: black;
    border: none;
    padding: 10px 29px;
    border-radius: 5px;
    cursor: not-allowed;
    transition: background 0.3s ease-in-out;
}

.add-btn.active {
    background-color: #c00;
    /* Green when valid */
    color: white;
    cursor: pointer;
}


.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.target-funnel-back-btn {
    /* background: white; */
    color: black;
    border: 1px solid #ccc;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.target-funnel-next-btn {
    background: #c00;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.target-funnel-error {
    color: #c00;
    font-size: 9px;
    /* margin-top: 5px; */
    display: block;
}

.target-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.target-card {
    /* background: #fff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center; */
    position: relative;
    display: flex;
    flex-direction: column;
    width: 200px;
    /* Adjust as needed */
    padding: 15px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 0%;
}

.target-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%;
    font-size: 16px;
    font-weight: bold; */
}

.color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.target-body {
    /* text-align: center;
    margin: 10px 0; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.target-container {
    display: flex;
    /* align-items: center; */
    gap: 15px;
    flex-wrap: wrap;
}

.add-target-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.target-funnel-edit {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.target-funnel-delete {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.monthly-label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.color-input::-webkit-color-swatch {
    border-radius: 50%;
    border: none;
    padding: 0;
}

.color-input::-moz-color-swatch {
    border-radius: 50%;
    border: none;
    padding: 0;
}

.daily-target {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.daily-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    gap: 5px;
}

.lead-body {
    /* display: flex; */
    /* justify-content: space-between;
    align-items: center; */
    margin-top: 10px;
}

.daily-label {
    display: flex;
    justify-content: space-between;
}

.lead-monthly-label {
    display: flex;
    justify-content: space-between;
}

.target-funnel-button-group {
    display: flex;
    align-items: center;
    gap: 15px;
    /* Adjust the space between buttons */
    padding: 26px 10px 10px 0px;
}


.back-btn {
    background: white;
    color: black;
    border: 1px solid #ccc;
    padding: 10px 29px;
    border-radius: 5px;
    cursor: not-allowed;
    transition: background 0.3s ease-in-out;
    margin-right: 15px;
}

.next-btn {
    background: #c00;
    color: white;
    border: none;
    padding: 10px 29px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}