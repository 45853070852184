.details>label {
    margin-bottom: 10px;
}

.details>label:first-of-type {
    margin-top: 10px;
}

.details>label:last-of-type {
    margin-bottom: 0;
}

.option {
    display: block;
    margin: 5px 10px 5px 0;
}

.grid-wrapper {
    display: flex;
    flex: 1 1 0px;
}

.grid-wrapper>div {
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}


.features-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.features-dropdown {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    width: 250px;
    z-index: 1000;
}



.ag-theme-alpine .ag-filter {
    max-width: 250px !important;
    /* Adjust filter width */
    padding: 10px !important;
    /* Add spacing inside filter */
    font-size: 14px !important;
    /* Make text more readable */
}

.ag-theme-alpine .ag-filter-apply-panel {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.ag-theme-alpine .ag-filter-apply-panel button {
    padding: 6px 12px;
    border-radius: 5px;
}

.custom-header {
    background-color: #007bff !important;
    /* Blue header */
    color: white !important;
    font-size: 14px;
    text-align: center;
}

/* Add borders to every row */
.ag-theme-alpine .ag-row {
    border-bottom: 1px solid #ddd; /* Light gray border for row separation */
}

.ag-theme-alpine .ag-row:last-child {
    border-bottom: none; /* Remove border from the last row */
}

/* Optional: Add borders to the cells */
.ag-theme-alpine .ag-cell {
    border-right: 1px solid #ddd; /* Light gray border for cell separation */
}

.ag-theme-alpine .ag-cell:last-child {
    border-right: none; /* Remove border from the last cell in each row */
}