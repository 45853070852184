.masters-configuration-cancel-button {
    height: 40px;
    width: 15%;
    border-color: none;
    border-radius: 4px;
    border: 1px solid #bcbcbc;
    cursor: pointer;
}

.masters-configuration-add-button {
    background-color: #C12328;
    border-color: #C12328;
    color: white;
    width: 80px;
    height: 40px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}