.sider {
  position: fixed;
  height: 85vh;
  left: 0;
  top: 15%;
  transition: width 0.1s ease-in-out;
  overflow: hidden;
  background-color: transparent;
  border-top-right-radius: 2%;
}


/* Expanded state */
.sider.expanded {
  width: 200px;
  background-color: white;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  transition: width 0.5s ease-in-out;
}

.sider.collapsed {
  width: 75px;
}

.sider.collapsed::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right,
      white 0%,
      white 60%,
      rgba(255, 255, 255, 0.8) 75%,
      rgba(255, 255, 255, 0.4) 85%,
      transparent 100%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.sider.collapsed:hover::after {
  background: linear-gradient(to right,
      white 0%,
      white 60%,
      rgba(255, 255, 255, 0.8) 75%,
      rgba(255, 255, 255, 0.4) 85%,
      transparent 100%);
  opacity: 1;
}

.sider:hover {
  background-color: white;
  transition: background-color 0.3s ease-in-out;
}


.sider.collapsed.fade-background::after {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}


.sider.collapsed.fade-background {
  background-color: transparent;
  transition: background-color 0.7s ease-in-out;
}

/* Fully collapsed state */
.sider.collapsed.background-hidden {
  background-color: transparent;
}

/* Menu styles */
.ant-menu {
  border-right: none !important;
  background: transparent !important;
  position: relative;
  z-index: 2;
}

.ant-menu-item {
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  color: #000;
  padding-left: 16px;
  margin: 4px 0 !important;
  position: relative;
  z-index: 2;
}

.ant-menu-item:hover {
  background-color: transparent !important;
}

.ant-menu-item-selected {
  background-color: transparent !important;
}

/* Add responsive styles */
@media screen and (max-width: 768px) {
  .responsive-sider {
    transform: translateX(0);
    transition: transform 20s ease !important;
  }

  .responsive-sider.ant-layout-sider-collapsed {
    transform: translateX(-10%);
  }

  .ant-menu-item span {
    opacity: 0;
    transition: opacity 20s;
  }
}

@media (max-width: 768px) {
  .sider {
    display: none;
  }
}