.createClientHeading {
    font-weight: bold;
    font-size: 23px;
    line-height: 29.26px;
    margin-top: -2%;
}

.self-registration-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    background-color: white;
}


.brandColorInput {
    margin-bottom: 2%;
    margin-right: 2%;
}

.self-registration-label {
    font-weight: bold;
    font-size: 18px;
}

.toggle-switch {
    position: relative;
    width: 40px;
    height: 20px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.self-registration-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 20px;
    transition: 0.4s;
}

.self-registration-slider::before {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

.toggle-switch input:checked+.self-registration-slider {
    background-color: #C12328;
}

.toggle-switch input:checked+.self-registration-slider::before {
    transform: translateX(20px);
}

.createClientHeading2 {
    font-weight: 500;
    font-size: 17px;
    line-height: 29.26px;
    margin-bottom: 0.6%;
}

/* .pencil-cursor {
    cursor: url('../Images//edit\ icon.png') 0 24, auto;
} */


.color-picking-cursor {
    cursor: url('../Images/edit_icon-removebg-preview.png') 1 1, auto !important;
}

.image-selecting {
    width: 30px;
    /* Fixed width */
    height: 30px;
    /* Fixed height */
    object-fit: contain;
    cursor: url('../Images/edit_icon-removebg-preview.png') 1 1, auto;
    border: 2px solid #C12328;
    box-sizing: border-box;
}

.picker-active {
    border: 2px solid #C12328 !important;
    transition: border-color 0.3s ease;
}

.pointer-events-disabled {
    pointer-events: none;
}

.upload-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.supported-file-text {
    font-size: 12px;
    color: #888;
    margin-top: 100px;
    margin-left: -7.5%;
}

.row-container {
    background-color: white;
    border: 1px solid #ccc;
    padding: 14px;
    border-radius: 8px;
    margin-bottom: 3%;
    margin-top: 0%;
}

.clientNameHeading {
    font-weight: bold;
    font-size: 17px;
    line-height: 29.26px;
}

.productAccessHeading {
    font-weight: 500;
    font-size: 17px;
    line-height: 29.26px;
}


.create-client-container {
    margin: 0 auto;
    padding: 20px;
}

.create-client {
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.back-arrow {
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
    color: #7f56d9;
}

h2 {
    font-size: 24px;
    color: #4a4a68;
    font-weight: bold;
}

.create-client-form {
    display: flex;
    flex-direction: column;
}



.form-row {
    display: flex;
    gap: 16px;
}

.form-input {
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #ddd;
    font-size: 14px;
}

.submit-button {
    width: 100%;
    background-color: #7f56d9;
    color: #fff;
    padding: 10px 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
}

.AddRoleImage {
    height: 13vh;
    width: 13vh;
    cursor: pointer;
    margin-top: 1%;
}

.create-page-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: -62%;
    margin-left: 74%;
    position: fixed;
    z-index: 1000;
    right: 20px;
    bottom: 88%;
}

.accountId-create-page-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: -88.7%;
    margin-left: 74%;
    position: fixed;
    z-index: 1000;
    right: 20px;
    bottom: 88%;

}

.discard-button {
    border-radius: 6px;
    border: 1px solid #4a4a68;
    cursor: pointer;
    margin-left: -6%;
}

.deleteButton {
    border: 1px solid #C12328;
    cursor: pointer;
    background-color: white;
    color: #C12328;
    border-radius: 7px;
    height: 40px;
    font-size: 14px;
    text-align: center;
    display: inline-flex;
    transition: all 0.3s ease;
    align-items: center;
    padding: 6px 20px;
}

.deleteIcon {
    width: 13px;
    height: 15px;
    margin-right: 8px;
    color: #C12328;
}


.create-page-buttons .discard-button,
.create-page-buttons .delete-button {
    width: 126px;
    height: 45px;
}

.accountId-create-page-buttons .discard-button,
.accountId-create-page-buttons .delete-button {
    width: 126px;
    height: 45px;
}

.lessthan {
    width: 5%;
    margin-left: -6%;
    margin-top: -3%;
    margin-right: 7%;

}

.delete-button {
    background-color: #C12328;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    transition: background-color 0.3s ease;
    border-radius: 6px;
    cursor: pointer;
}

.role-management-modal-footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 21%;
}

.brand-color-label {
    font-size: 15px;
}

.brand-color-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.brandcolorinputfield {
    cursor: pointer;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    position: relative;
}

.color-display-circle {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    /* pointer-events: none; */
}

.color-picker-container {
    position: absolute;
    z-index: 999;
    margin-top: 10px;
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-chrome-picker .chrome-picker {
    box-shadow: none !important;
}

.close-picker-button {
    margin-top: 5px;
    background: transparent;
    border: none;
    color: #007BFF;
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}


.createClientErrors {
    color: #C12328;
}

.image-upload-circle {
    cursor: pointer;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
}

.delete-button:hover {
    background-color: #C12328;
    color: white;
    transition: none;
}

.delete-button .CreateIcon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    margin-left: 23%;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: black !important;
    border-color: black !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white !important;
}


.custom-radio .ant-radio-inner {
    border-color: #d9d9d9;
    background-color: white;
}

.custom-radio .ant-radio-checked .ant-radio-inner {
    border-color: rgb(14, 14, 14) !important;
    background-color: rgb(14, 14, 14) !important;
}

.custom-radio .ant-radio-checked .ant-radio-inner::after {
    background-color: white;
}

.custom-radio .ant-radio:hover .ant-radio-inner {
    border-color: rgb(14, 14, 14) !important;
}

.ant-form-item-explain-error {
    color: #C12328 !important;
}

.ant-form-item-has-error .ant-input {
    border-color: #C12328 !important;
}

.ant-form-item-has-error .ant-input:hover {
    border-color: #C12328 !important;
}

.ant-form-item-has-error .ant-input:focus {
    border-color: #C12328 !important;
    box-shadow: 0 0 0 2px rgba(193, 35, 40, 0.1) !important;
}

.clientNameHeading {
    font-size: 20px;
    font-weight: bold;
}

.role-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
    align-items: flex-start;
}

.role-card {
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 14%;
    height: auto;
    margin-top: 1%;
}

.role-card .role-title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    word-break: break-word;
    /* Ensures long words wrap */
    white-space: normal;
}

.AddRoleImage {
    cursor: pointer;
    width: 93px;
    height: 93px;
    margin-top: 13px;
}

.ant-modal-footer {
    display: flex;
    justify-content: center;
}

.ant-btn {
    width: 146px;
    height: 35px;
    font-weight: 500;
}

.ant-btn-danger {
    background-color: #C12328;
    color: white;
    border: #C12328;
}

.ant-btn-default {
    background-color: grey;
    color: white;
    border: black;
}


.cancel-button {
    border-radius: 6px;
    border: 1px solid #4a4a68;
    cursor: pointer;
    width: 112px;
    height: 38px;
}

.create-button {
    border-radius: 6px;
    border: 1px solid #C12328;
    cursor: pointer;
    width: 112px;
    height: 38px;
    background-color: #C12328;
    color: white;
}

.create-button:hover {
    border: 1px solid #C12328;
    background-color: #C12328;
    color: white;
}

.rolemanagementdeletebutton {
    width: 14px;
    cursor: pointer;
}

.rolemanagementeditbutton {
    width: 14px;
    height: 15px;
    display: flex;
    justify-content: center;
    margin-top: 9%;
}

.rolemanagementeditandimage {
    border-radius: 60px;
    border: 1px solid #FAC9CA;
    cursor: pointer;
    width: 65px;
    height: 26px;
    background-color: #FAC9CA;
    color: white;
    display: flex;
    margin-top: 8%;
    justify-content: center;
    gap: 8%;
}

.rolemanagementeditname {
    margin-top: 8%;
    color: black;
    font-family: 'Inter';
    font-size: 14px;
}

.role-label {
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
}


.delete-modal-footer {
    margin-top: 24px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 12px;
}

.delete-account {
    height: 45px;
    width: 30%;
    border-color: #C12328;
    border-radius: 4px;
    background-color: #C12328;
    color: white;
    cursor: pointer;
}


.delete-cancel {
    height: 45px;
    width: 30%;
    border-color: none;
    border-radius: 4px;
    border: 1px solid #bcbcbc;
    cursor: pointer;
}


.brand-color-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adds spacing between input and color circle */
}

@media (max-width: 767px) {
    .create-client-container {
        padding: 10px;
    }

    .upload-container {
        text-align: center;
    }

    .upload-icon {
        width: 80px;
        height: 80px;
    }

    .row-container {
        display: block;
        width: 100%;
        margin-top: 10%;
    }

    .createClientHeading {
        font-size: 25px;
        text-align: center;
        margin-top: 20%;
    }


    .createClientHeading2 {
        font-weight: 500;
        font-size: 17px;
        line-height: 29.26px;
        margin-bottom: 0.6%;
        text-align: center;
    }

    .image-upload-circle {
        cursor: pointer;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;
    }

    .supported-file-text {
        font-size: 12px;
        color: #888;
        margin-top: 100px;
        margin-left: -28.5%;
    }

    label {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .clientNameHeading {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .row-container .ant-col {
        width: 100% !important;
        margin-bottom: 15px;
        margin-right: 0 !important;
    }

    .createClientErrors {
        font-size: 12px;
        color: #C12328;
    }

    .create-page-buttons {
        display: flex;
    }

    .accountId-create-page-buttons {
        display: flex;
        justify-content: space-between;
        /* gap: 138px; */
        position: fixed;
        z-index: 1000;
        bottom: 91%;
    }

    .accountId-create-page-buttons .delete-button {
        width: 93px;
        height: 35px;
    }

    .accountId-create-page-buttons .discard-button,
    .accountId-create-page-buttons .delete-button {
        width: 93px;
        height: 35px;
    }

    .create-page-buttons .discard-button,
    .create-page-buttons .delete-button {
        width: 98px;
        height: 38px;
    }

    .discard-button {
        order: 1;
        justify-content: flex-start;
    }

    .delete-button {
        order: 2;
        justify-content: flex-end;
    }

    .discard-button,
    .delete-button {
        width: 100%;
        margin: 5px 0;
        font-size: 14px;
    }

    .create-page-buttons {
        display: flex;
        justify-content: space-between;
        /* gap: 131px; */
        position: fixed;
        z-index: 1000;
        bottom: 91%;
    }


    .lessthan,
    .CreateIcon {
        width: 9px;
        height: 11px;
        margin-right: 10px;
        margin-top: 2%;
    }

    .custom-chrome-picker {
        width: 100% !important;
    }

    .custom-radio {
        width: 100%;
        text-align: center;
    }

    .upload-container {
        height: 18vh;
        justify-content: center;
    }

    .brandcolorinputfield {
        cursor: pointer;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 157%;
        position: relative;
    }

    .color-display-circle {
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        /* pointer-events: none; */
    }

    .role-card {
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        padding: 16px;
        margin-bottom: 16px;
        background-color: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        width: 47%;
        height: auto;
        margin-top: 1%;
    }

    .deleteButton {
        height: 36px;
        /* Slightly smaller height for mobile screens */
        font-size: 12px;
        /* Smaller font size */
        padding: 0 8px;
        /* Reduce padding on smaller screens */
    }

    .deleteIcon {
        width: 16px;
        /* Smaller icon size */
        height: 16px;
        /* Smaller icon size */
        margin-right: 6px;
        /* Reduce space between icon and text */
    }

}

@media (min-width: 768px) and (max-width : 900px) {
    .create-client-container {
        padding: 10px;
    }

    .upload-container {
        text-align: center;
    }

    .upload-icon {
        width: 80px;
        height: 80px;
    }

    .row-container {
        /* display: block; */
        width: 100%;
        /* margin-top: 10%; */
        gap: 3%;
    }


    .header-container {
        padding: 0 20px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 3;
        height: 10%;
    }

    .createClientHeading {
        font-size: 25px;
        text-align: center;
        margin-top: 5%;
    }


    .createClientHeading2 {
        font-weight: 500;
        font-size: 17px;
        line-height: 29.26px;
        margin-bottom: 0.6%;
        text-align: center;
    }

    .image-upload-circle {
        cursor: pointer;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;
    }

    .supported-file-text {
        font-size: 12px;
        color: #888;
        margin-top: 100px;
        margin-left: -13.5%;
    }

    label {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .clientNameHeading {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .row-container .ant-col {
        width: 100% !important;
        margin-bottom: 15px;
        margin-right: 0 !important;
    }

    .createClientErrors {
        font-size: 12px;
        color: #C12328;
    }

    .create-page-buttons {
        display: flex;
    }

    .accountId-create-page-buttons {
        display: flex;
        position: fixed;
        z-index: 1000;
        bottom: 93%;
    }

    .accountId-create-page-buttons .delete-button {
        width: 93px;
        height: 35px;
    }

    .accountId-create-page-buttons .discard-button,
    .accountId-create-page-buttons .delete-button {
        width: 93px;
        height: 35px;
    }

    .create-page-buttons .discard-button,
    .create-page-buttons .delete-button {
        width: 98px;
        height: 38px;
    }

    .discard-button {
        order: 1;
        justify-content: flex-start;
    }

    .delete-button {
        order: 2;
        justify-content: flex-end;
    }

    .discard-button,
    .delete-button {
        width: 100%;
        margin: 5px 0;
        font-size: 14px;
    }

    .create-page-buttons {
        display: flex;
        justify-content: space-between;
        /* gap: 131px; */
        position: fixed;
        z-index: 1000;
        bottom: 91%;
    }


    .lessthan,
    .CreateIcon {
        width: 9px;
        height: 11px;
        margin-right: 10px;
        margin-top: 2%;
    }

    .custom-chrome-picker {
        width: 100% !important;
    }

    .custom-radio {
        width: 100%;
        text-align: center;
    }

    .upload-container {
        height: 18vh;
        justify-content: center;
    }

    .brandcolorinputfield {
        cursor: pointer;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 190%;
        position: relative;
    }

    .color-display-circle {
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        /* pointer-events: none; */
    }

    .role-card {
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        padding: 16px;
        margin-bottom: 16px;
        background-color: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        width: 47%;
        height: auto;
        margin-top: 1%;
    }

    .deleteButton {
        height: 36px;
        /* Slightly smaller height for mobile screens */
        font-size: 12px;
        /* Smaller font size */
        padding: 0 8px;
        /* Reduce padding on smaller screens */
    }

    .deleteIcon {
        width: 16px;
        /* Smaller icon size */
        height: 16px;
        /* Smaller icon size */
        margin-right: 6px;
        /* Reduce space between icon and text */
    }

}

@media (min-width: 901px) and (max-width: 1024px) {
    .create-client-container {
        padding: 15px;
    }

    .upload-container {
        text-align: center;
    }

    .upload-icon {
        width: 90px;
        height: 90px;
    }

    .row-container {
        width: 100%;
        gap: 3%;
    }

    .header-container {
        padding: 0 15px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 3;
        height: 60px;
    }

    .createClientHeading {
        font-size: 24px;
    }

    .image-upload-circle {
        cursor: pointer;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #f0f0f0;
    }

    .supported-file-text {
        font-size: 12px;
        color: #888;
        margin-top: 80px;
        margin-left: -10%;
    }

    .create-page-buttons {
        display: flex;
        justify-content: space-between;
        /* gap: 100px; */
        position: fixed;
        z-index: 1000;
        bottom: 91%;
    }

    .discard-button,
    .delete-button {
        width: 100%;
        margin: 5px 0;
        font-size: 14px;
    }

    .lessthan,
    .CreateIcon {
        width: 9px;
        height: 11px;
        margin-right: 10px;
        margin-top: 2%;
    }

    .custom-chrome-picker {
        width: 100% !important;
    }

    .custom-radio {
        width: 100%;
        text-align: center;
    }

    .role-card {
        width: 47%;
        height: auto;
    }

    .deleteButton {
        height: 36px;
        font-size: 12px;
        padding: 0 8px;
    }

    .deleteIcon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
    }
}


@media screen and (min-device-width: 1281px) and (max-device-width: 2560px) {
    .image-upload-circle {
        cursor: pointer;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;
        margin-left: 20px;
    }

    .color-display-circle {
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        /* pointer-events: none; */
    }

    .brandColorInput {
        margin-bottom: 2%;
        margin-right: -17%;
    }

    .brandcolorinputfield {
        cursor: pointer;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
        position: relative;
        /* flex-grow: 1;
        min-width: 150px; */

    }
}