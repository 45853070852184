.set-password-container {
    background-color: #F5F5F5;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 26%;
    text-align: center;
}

.confirmPasswordContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FDEDED
}

.password-box {
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    width: 324px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: 400px;
}

.confirmPasswordLogo {
    width: 100px;
    margin-bottom: 20px;
}

.info-text {
    color: #e63946;
    font-size: 14px
}

.confirmPasswordInput {
    border: 1px solid #ccc;
    outline: none;
    padding: 17px;
    border-radius: 22px;
    display: flex;
    width: 89%;
}

.input-group {
    margin-top: 5%;
}

.submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.submitButton {
    background-color: #6C3FEB;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #5a32c1;
}

.submitButton.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.error-message {
    color: #C12328;
    /* Highlight error message in red */
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.transaction-id {
    margin: 20px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}


.error-message-container {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    padding: 15px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    animation: slideIn 0.3s ease-out;
}

.error-message-container p {
    margin: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    flex-grow: 1;
}

.error-message-container .error-icon {
    margin-right: 10px;
    font-size: 20px;
    color: #721c24;
}

.logo {
    width: 175px;
    height: auto;
    margin-bottom: -11px;
    margin-left: 10%;
}

.no-tid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
}

.no-tid-message {
    color: #dc3545;
    font-size: 18px;
    font-weight: bold;
}

.success-message {
    color: green;
    font-size: 16px;
    font-weight: bold;
}

.confirmPasswordInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 13px;
    background-color: #F5F5F5;
    position: relative;
    border: 1px solid #ccc;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.confirm-password-text {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    margin-top: 1%;
}

.confirm-password-icon {
    width: 15px;
    height: 15px;
    margin-right: 4px;
    margin-top: -3%;
}


.confirm-password-error-message {
    color: #C12328;
    font-size: 13px;
    font-weight: 500;
    text-align: left;

}

.confirm-password-success-message {
    color: green;
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
}


.password-field {
    position: relative;
    width: 100%;
}

.password-toggle {
    position: absolute;
    top: 47%;
    right: 39%;
    cursor: pointer;
    transform: translateY(-50%);
}

.password-toggles {
    position: absolute;
    top: 66%;
    right: 39%;
    cursor: pointer;
    transform: translateY(-50%);
}


.confirmPasswordInput:focus {
    background-color: #e0e0e0;
    border-color: none;
    outline: none;
}

.confirmPasswordInput::placeholder {
    color: #a0a0a0;
}

.confirmPasswordInput:not(:focus) {
    background-color: #F5F5F5;
}


.ant-input-password {
    background-color: #F5F5F5;
    border: 1px solid #ccc;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.ant-input-password:active {
    background-color: #e0e0e0;
    border-color: black;
    box-shadow: none;
    outline: none;
}

/* If you also want to change the placeholder text color */
.ant-input-password::placeholder {
    color: #a0a0a0;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within {
    /* border-color: none; */
    box-shadow: none;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:hover {
    border-color: none
}


/* Animation for sliding in the error message */
@keyframes slideIn {
    from {
        transform: translateX(-20px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Responsive handling for smaller screens */
@media (max-width: 600px) {
    .error-message-container {
        padding: 12px;
        /* Reduce padding on small screens */
        font-size: 14px;
        /* Slightly smaller text */
    }
}


@media (max-width: 1024px) {
    .set-password-container {
        width: 40%;
        margin: 20px;
    }

    .password-toggle {
        right: 30%;
    }

    .password-toggles {
        right: 30%;
    }
}

@media (max-width: 768px) {
    .set-password-container {
        width: 50%;
        margin: 20px;
    }

    .confirmPasswordInput {
        font-size: 12px;
        width: 100%;

    }

    .password-toggle,
    .password-toggles {
        right: 20%;
    }
}

@media (max-width: 480px) {

    .continue-btn {
        width: 100%;
        padding: 12px;
        background-color: #C12328;
        color: white;
        border-radius: 4px;
        border: none;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .set-password-container {
        width: 90%;
        padding: 20px;
        margin: 20px;
    }

    .confirmPasswordInput {
        font-size: 11px;
        padding: 8px;
        width: 100%;
    }

    .password-toggle,
    .password-toggles {
        top: 50%;
        right: 10%;
    }

    .confirm-password-error-message,
    .confirm-password-success-message {
        font-size: 12px;
    }
}

@media (max-width: 320px) {

    .continue-btn {
        width: 100%;
        padding: 12px;
        background-color: #C12328;
        color: white;
        border-radius: 4px;
        border: none;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .set-password-container {
        width: 100%;
        padding: 15px;
    }

    .confirmPasswordInput {
        font-size: 10px;
        padding: 6px;
        width: 100%;

    }

    .password-toggle,
    .password-toggles {
        right: 5%;
    }

    .confirm-password-error-message,
    .confirm-password-success-message {
        font-size: 11px;
    }
}