.target-funnel-preview {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.funnel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.funnel-row {
    display: flex;
    align-items: center; /* Keep everything vertically aligned */
    justify-content: flex-start; /* Align elements in a row */
    gap: 10px;
}

.funnel-stage {
    display: flex;
    justify-content: flex-start;
    margin-top: -14px;
}

.funnel-image {
    width: 100%;
    max-width: 250px;
    height: auto;
    object-fit: none;
}

.red-line-container {
    width: 50px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}


.input-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

/* .red-line {
    height: 4px;
    background-color: red;
    margin-right: 10px;
} */

.stage-input {
    width: 100%; /* Ensures input takes up full space */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.funnel-actions {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.back-btn,
.proceed-btn {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.back-btn {
    background-color: #6c757d;
    color: white;
}

.proceed-btn {
    background-color: #28a745;
    color: white;
}

.back-btn:hover {
    background-color: #545b62;
}

.proceed-btn:hover {
    background-color: #218838;
}