.password_input_field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 155%;
    color: #A7A7A7;
    font-family: Montserrat;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.password_input_field::placeholder {
    color: #A7A7A7;
}

.password_input_field:focus {
    /* border: 1.8px solid #6C3FEB; */
    outline: none;
    /* box-shadow: 0 0 5px rgba(108, 63, 235, 0.5); */
}

@media (min-width: 200px) and (max-width: 480px) {
    .password_input_field {
        /* width: 100%; */
        /* Adjust the width for screens between 200px and 480px */
        /* height: 5vh; */
        font-size: 11px;

    }
}



@media (min-width: 481px) and (max-width: 576px) {
    .password_input_field {
        /* width: 100%; */
        /* Adjust the width for screens wider than 576px */
        /* height: 6vh; */
        font-size: 13px;

        /* line-height:1px; */
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .password_input_field {
        /* width: 100%; */
        /* Adjust the width for screens wider than 768px */

        /* line-height:3px; */
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .password_input_field {

        /* width: 100%; */
        /* Adjust the width for screens wider than 992px */
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .password_input_field {
        /* width: 100%; */
        /* Adjust the width for screens wider than 1200px */
    }
}

.error-message {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 0.7rem;
    color: #C12328;
}