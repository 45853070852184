.logo-icon {
    width: 187px;
    height: 40px;
    margin-right: -7px;
}

.main-container {
    display: flex;
    margin-top: 60px;
    /* Adjust this value to match the header height */
    height: calc(100vh - 60px);
    /* Adjust this value to match the header height */
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.8);
    /* Optional: overlay effect */
    animation: fadeIn 0.5s ease-in-out;
    /* Fade-in effect */
}

.loading-image {
    width: 40%;
    height: 40%;
    animation: expand 1.5s infinite ease-in-out;
    margin-left: 10%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes expand {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}



.logo-container {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.2%;
    /* background: #001529; */
}

.header-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-layout-sider .ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: #000000;
    text-align: center;
    background: #ffffff;
    cursor: pointer;
    transition: all 0.2s;
}

/* :where(.css-dev-only-do-not-override-1x0dypw).ant-layout {
    background-color: #F0EBFD;
} */

.create-client {
    margin-top: 28%;
    cursor: pointer;
}

.client-heading {
    font-size: 18px;
    font-weight: 900;
    color: #6C3FEB;
    margin-bottom: 8px;
    font-family: Montserrat;
    cursor: pointer;
}

.client-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    background-color: #f9fbfd;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 35px;
}

.logo-section {
    flex: 0 0 auto;
}

.client-logo {
    width: 130px;
    height: 130px;
    object-fit: contain;
    border-radius: 50%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.info-section {
    flex: 1;
    margin-left: 35px;
}

.company-name {
    font-size: 32px;
    font-weight: 600;
    color: #333;
    margin-bottom: 12px;
}

.details-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 15px;
}

.details-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #555;
}

.details-item strong {
    font-weight: 600;
    color: #333;
    margin-right: 10px;
}

.details-item a {
    color: #0073e6;
    text-decoration: none;
}

.details-item a:hover {
    text-decoration: underline;
}

.brand-color-swatch {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 12px;
}

@media (max-width: 768px) {
    .details-list {
        grid-template-columns: 1fr;
    }
}

.brand-color-swatch {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 12px;
}

.info-section p:last-child {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .client-header-wrapper {
        flex-direction: column;
        text-align: center;
    }

    .logo-section {
        margin-bottom: 25px;
    }

    .info-section {
        margin-left: 0;
    }
}


.inline-buttons {
    display: flex;
    gap: 4px;
    margin: 8px 0;
}

.small-button {
    padding: 4px 8px;
    font-size: 12px;
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.small-button:hover {
    background-color: #e0e0e0;
    border-color: #aaa;
}

.brand-color-swatch {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #ccc;
}

content {
    margin-left: 80px;
    transition: margin-left 0.3s;
    padding: 24px;
    background: #f0f2f5;
}

.content.expanded {
    margin-left: 200px;
}

.home-content {
    margin-left: 5%;
    margin-top: -0.5%;

}

.heading {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
    line-height: 29.26px;
    margin-bottom: 2%;
}


.card {
    /* width: calc(100% - 20px); */
    /* max-width: 300px; */
    height: 235px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    border: 2px solid #A58AF3;
    border-radius: 12px;
    background: #F0EBFD;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.card-image {
    width: 50%;
    height: 50px;
    margin-bottom: 8px;
    border-radius: 50%;
    object-fit: contain;
    background: white;
    padding: 3px;
    border: 1px solid grey;
    display: block;
}

.card-title {
    font-size: 16px;
    font-weight: 500;
    /* color: rgba(0, 0, 0); */
    margin-bottom: 8px;
    color: #1E1E1E;
    font-family: "Inter";
}

.card-button {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(108, 63, 235, 1);
    color: white;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    width: 120px;
    height: 35px;
    cursor: pointer;
    text-align: center;
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
    .home-content {
        padding: 24px;
        /* background-color: white; */
        border-radius: 8px;
        margin-left: 1%;
        margin-top: 16%;
    }
}

@media only screen and (min-width: 602px) and (max-width: 800px) {
    .home-content {
        padding: 24px;
        /* background-color: white; */
        border-radius: 8px;
        margin-left: 1%;
        margin-top: 7%;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
    .home-content {
        padding: 24px;
        /* background-color: white; */
        border-radius: 8px;
        margin-left: 1%;
        margin-top: 10%;
    }
}

@media only screen and (min-width: 1002px) and (max-width: 1024px) {
    .home-content {
        padding: 24px;
        /* background-color: white; */
        border-radius: 8px;
        margin-left: 1%;
        margin-top: 8%;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1166px) {
    .home-content {
        padding: 24px;
        /* background-color: white; */
        border-radius: 8px;
        margin-left: 1%;
        margin-top: 3%;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1288px) {
    .home-content {
        /* margin-top: 0%; */
    }
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 11px;
    padding: 24px;
}

.card {
    background: white;
    border: 1px solid #848484;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 0 10px 5px rgb(216 216 216 / 70%);
    display: flex;
    flex-direction: column;
    min-height: 200px;
    transition: box-shadow 0.3s;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.placeholderImage {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
    /* Grey color for placeholder */
    border-radius: 50%;
}

.placeholder-text {
    font-size: 12px;
    color: #fff;
    text-align: center;
}


.edit-icon {
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-left: auto;
    margin-bottom: 16px;
}

.card-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
    background: white;
    padding: 3px;
    border: 1px solid grey;
}

.card-title {
    /* text-align: center; */
    font-size: 16px;
    font-weight: 500;
    color: #1E1E1E;
    margin-top: 8px;
    display: flex;
    align-self: baseline;
    font-family: "Inter";
}

.card-actions {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: auto;
}

.view-role-button {
    width: 100%;
    background-color: #C12328;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
    /* margin-bottom: 13px; */

}

.view-role-button:hover {
    background-color: #C12328;
}

/* Override Ant Design Select styles */
.role-select .ant-select-selector {
    border-radius: 4px !important;
}


/* CSS for Disabled Dropdown */
.ant-select-disabled {
    background-color: #dcdcdc !important;
    color: #a5a5a5 !important;
    border-color: #b0b0b0 !important;
}

/* Disabled Dropdown - for the dropdown options */
.ant-select-disabled .ant-select-selector {
    background-color: #dcdcdc !important;
    color: #a5a5a5 !important;
    border-color: #b0b0b0 !important;
}

/* CSS for Disabled Button */
.view-role-button:disabled {
    background-color: #dcdcdc;
    /* Light grey background */
    color: #a5a5a5;
    /* Grey text */
    border: 1px solid #b0b0b0;
    /* Grey border */
    cursor: not-allowed;
    /* Indicate disabled state with no pointer */
}

/* Optional: If you have a 'disabled' class on the button */
.view-role-button.disabled {
    background-color: #b4b4b4;
    color: #767676;
    border: 1px solid #b0b0b0;
    cursor: not-allowed;
}


.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* Or another value based on your layout */
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #C12328;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.searchinput {
    /* width: 50%;
    max-width: 600px; */
    padding: 10px 40px 10px 20px;
    border: 1px solid #ccc;
    border-radius: 100px;
    font-size: 16px;
    /* margin-top: -6.5%;
    margin-left: 18%;
    position: fixed;
    z-index: 100; */
    margin: 0px 0px 0px 19px;
}


.search-wrapper {
    position: relative;
    width: 100%;
    max-width: 400px;
    /* Adjust max width as needed */
}

.searchinput:focus {
    border-color: #ccc;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.searchinput:hover {
    border-color: #ccc;
}

.searchicon {
    position: absolute;
    left: 67%;
    right: 12px;
    top: 35px;
    width: 20px;
    height: 20px;
    pointer-events: none;
    position: fixed;
    z-index: 1000;

    transform: translateY(-50%);
}


.add-roles-container {
    display: flex;
    justify-content: space-between;
    /* Moves the button to the right */
    padding: 0px 28px;
}

.add-roles-button {
    background-color: #C12328;
    /* Customize color */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-roles-button:hover {
    background-color: #A91E24;
    transform: scale(1.05);
}

.bold-text {
    font-weight: bold;
    color: 1px solid #000000;
    font-family: Inter;
}

@media (max-width: 768px) {
    .search-container {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .search-container {
        width: 90%;
    }

    .search-wrapper {
        max-width: 90%;
        /* Full width for small screens */
    }

    .searchinput {
        font-size: 14px;
        padding: 8px 35px 8px 15px;
        /* Adjust padding for smaller screens */
    }

    .search-icon {
        display: none;

    }
}




/* Media Queries for Responsive Layout */
@media (max-width: 768px) {
    .cards-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
        /* height: calc(100vh - 90px); */
        padding: 12px;
    }

    .content {
        padding: 16px;
    }

    .home-content {
        margin-left: 5%;
        margin-top: 6.5%;
    }
}

@media (max-width: 480px) {
    .cards-container {
        grid-template-columns: 1fr;
        top: 20%;
        bottom: 20%;
    }

    .content {
        padding: 12px;
    }

    .card {
        width: 191px;
        min-height: 260px;
    }

    .logo-container img {
        height: 26px;
    }

    .home-content {
        margin-top: 20.5%;
    }
}

/* @media only screen and (max-width: 768px) {
    .searchinput {
        width: 100%;
        font-size: 14px;
        padding: 8px;
    }

    .searchicon {
        width: 20px;
        height: 20px;
        margin-left: -28px;
    }
} */


@media only screen and (min-width: 481px) and (max-width: 768px) {
    .searchinput {
        font-size: 15px;
        padding: 12px;
        width: 41%;
        margin-top: -17%;
    }

    .search-wrapper {
        max-width: 75%;
    }

    .searchicon {
        display: none;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .searchinput {
        font-size: 16px;
        padding: 14px;
        width: 45%;
        margin: 0 auto;
        display: block;
        margin-top: -19%;
        margin-left: 16%;
    }




    .searchicon {
        display: none;

    }
}


@media only screen and (min-width: 0px) and (max-width: 470px) {
    .searchicon {
        display: none;
    }

    .searchinput {
        width: 41%;
        font-size: 12px;
        padding: 6px;
        margin-top: -27%;
        margin-left: 27%;
    }
}


/* Extra small devices */
@media only screen and (max-width: 480px) {
    .searchinput {
        width: 41%;
        font-size: 12px;
        padding: 6px;
        margin-top: -27%;
        margin-left: 27%;
    }

    .searchicon {
        display: none;
    }
}

@media screen and (min-device-width: 1272px) and (max-device-width: 1300px) {
    .add-roles-container {
        display: flex;
        justify-content: space-between;
        padding: 0px 28px;
        margin-top: 14px;
    }
}

@media screen and (min-device-width: 1372px) and (max-device-width: 1400px) {
    .add-roles-container {
        display: flex;
        justify-content: space-between;
        padding: 0px 28px;
        margin-top: 48px;
    }

    .searchinput {
        width: 50%;
        max-width: 600px;
        padding: 10px 40px 10px 20px;
        border: 1px solid #ccc;
        border-radius: 100px;
        font-size: 16px;
        margin-top: -7.5%;
        /* margin-top: -5.9%; */
        margin-left: 18%;
        position: fixed;
        z-index: 100;
    }
}

@media screen and (max-device-width: 1280px) {
    .add-roles-container {
        /* display: flex;
        justify-content: space-between;
        padding: 0px 28px; */
        /* margin-top: -28px; */
    }
}


/* Small devices (phones, 480px and down) */
@media (max-width: 480px) {
    .home-content {
        margin-left: 2%;
        margin-top: 15%;
    }

    .cards-container {
        grid-template-columns: 1fr;
        padding: 10px;
    }

    .card {
        width: 100%;
        min-height: 240px;
    }

    .searchinput {
        width: 90%;
        font-size: 14px;
        padding: 8px;
    }

    .searchicon {
        display: none;
    }
}

/* Medium devices (tablets, 481px - 768px) */
@media (min-width: 481px) and (max-width: 768px) {
    .home-content {
        margin-left: 3%;
        margin-top: 10%;
    }

    .cards-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        padding: 12px;
    }

    .searchinput {
        /* width: 80%; */
    }

    .searchicon {
        left: 90%;
    }
}

/* Large devices (small laptops, 769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .home-content {
        margin-left: 4%;
        margin-top: 7%;
    }

    .cards-container {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 15px;
    }

    .searchinput {
        width: 60%;
    }
}

/* Extra large devices (desktops, 1025px and up) */
@media (min-width: 1025px) {
    .home-content {
        margin-left: 5%;
        margin-top: 1%;
    }

    .cards-container {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 20px;
    }

    .searchinput {
        /* width: 50%; */
    }
}
