.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #202020 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
  transition: transform 0.3s ease;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #545454;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
  /* margin-right: 84px; */
}

.dropdown .options {
  background-color: #fff;
  border: 1px solid #545454;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transform-origin: top;
  transform: scaleY(0);
}

/* Add these styles to your existing CSS file */
.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  pointer-events: none;
}

.selected-value {
  position: relative;
  width: 100%;
}

.dropdown .options.open {
  max-height: 200px;
  transform: scaleY(1);
  overflow-y: auto;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  transition: background-color 0.2s ease;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}


/* Style for the dropdown input when it is disabled */
.disabled-dropdown {
  background-color: #e0e0e0 !important;
  color: #818181 !important;
  border-color: #b0b0b0 !important;
  /* Grey border */
  cursor: not-allowed;
  /* Make it non-interactive */
}

.dropdown .options .no-options {
  color: #a5a5a5;
  /* Grey text for no options available */
}

.disabled-dropdown::placeholder {
  color: #a5a5a5 !important;
  /* Grey placeholder text */
}

/* Style for the arrow when dropdown is disabled */
.disabled-dropdown .arrow {
  border-color: #a5a5a5 transparent transparent;
  /* Grey arrow */
  cursor: not-allowed;
}

/* Style for the arrow in the active state */
.arrow {
  border-color: #202020 transparent transparent;
  /* Default dark arrow */
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .control {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.dropdown input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.options {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  position: absolute;
  width: 100%;
  z-index: 1;
  opacity: 0;
  /* visibility: hidden; */
}


.dropdown.open .options {
  max-height: 500px;
  /* padding: 8px 0; */
  opacity: 1;
  /* visibility: visible; */
}

.option {
  padding: 8px;
  cursor: pointer;
}


.option:hover {
  background-color: #f0f0f0;
}

.no-options {
  padding: 8px;
  color: #aaa;
}


.search-container {
  padding: 8px;
  position: relative;
  border-bottom: 1px solid #eee;
}

.search-input {
  width: 100%;
  padding: 8px 8px 8px 32px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.search-input:focus {
  border-color: #999;
}

.search-icon {
  position: absolute;
  left: 89%;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  pointer-events: none;
}


.dropdown.disabled-dropdown {
  background-color: #e0e0e0; /* Grey background for disabled state */
  cursor: not-allowed; /* Change cursor to indicate it is disabled */
}

.dropdown.disabled-dropdown .selected-value input {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}
