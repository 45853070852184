  /* Main Content */
  .main-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #f3f4f6;
      margin-left: 5%;
  }

  .header-title {
      font-size: 20px;
      font-weight: 600;
  }



  .global-admin-CreateIcon {
      margin-right: 5px;
      width: 18px;
  }

  .global-admin-modal-footer {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
  }

  .header-user {
      font-size: 14px;
  }

  .global-admin-addNew-button {
      background-color: #C12328;
      color: white;
      padding: 8px 12px;
      border-radius: 6px;
      font-weight: 500;
      cursor: pointer;
      border-color: #C12328;
      margin-top: -2%;
      white-space: nowrap;
  }

  /* Search Bar */
  .search-container-global-admin {
      padding: 16px;
  }

  .search-box {
      padding-left: 40px;
      border: 1px solid #d1d5db;
      height: 60px;
      border-radius: 100px;
  }

  /* User List */
  .user-list-container {
      padding: 16px;
  }

  .user-list {
      padding: 16px;
      border-radius: 8px;
      margin-top: -3%;
  }

  .user-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
      padding: 0px 36px 0px 19px;
  }

  .user-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 1%;
      gap: 20px;

  }

  .user-details {
      display: flex;
      align-items: center;
      gap: 12px;
      flex: 0.1 0;
      min-width: 0;
  }

  .user-avatar {
      width: 44px;
      height: 44px;
      background-color: #d1d5db;
      border-radius: 50%;
      gap: "10px";
  }

  .user-role {
      padding: 6px 10px;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 500;
  }

  .edit-button {
      background-color: #e5e7eb;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
  }


  .header-content {
      display: flex;
      align-items: center;
      margin: 0px 22px 0px 0px;
      /* width: 100%;
    gap: 55%;
    margin-top: -2%; */

  }

  .search-container-global-admin {
      flex-grow: 1;
      /* max-width: 1055px; */
  }

  .add-button {
      white-space: nowrap;
      /* Prevents button text from wrapping */
      padding: 8px 12px;
      border-radius: 6px;
      background-color: #dc2626;
      color: white;
      font-weight: 500;
      cursor: pointer;
  }

  /* .user-list-header {
    display: grid;
    grid-template-columns: 0.9fr 1fr 0.2fr;
    padding: 10px;
    background: #f3f4f6;
    font-weight: bold;
    border-radius: 8px;
} */


  /* .user-list-header {
    display: grid;
    grid-template-columns: 3fr 5fr 3fr 1fr;
    padding: 10px;
    background: #f3f4f6;
    font-weight: bold;
    border-radius: 8px;
} */

  .user-list-header,
  .user-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
  }

  .user-list-header {
      font-weight: bold;
      background-color: #f5f5f5;
      padding: 10px;
      border-radius: 5px;
  }


  .user-role-dropdown {
      padding: 1px;
      border-radius: 100px;
      cursor: pointer;
      font-size: 14px;
      border: none;
      outline: none;
      background-color: transparent;
      min-width: 175px;
      flex-shrink: 0;
  }

  .user-role-dropdown:focus {
      outline: none;
  }

  .option-global-admin {
      background-color: #F8D7DA;
      /* Global Admin */
  }

  .option-admin {
      background-color: #FAD7A0;
      /* Admin */
  }

  .option-user {
      background-color: #D5F5E3;
      /* User */
  }


  .global-admin-edit-button {
      display: flex;
      align-items: center;
      gap: 8px;
      /* Space between text and icon */
      background-color: #d1d1d1;
      /* Grey background */
      border: none;
      border-radius: 20px;
      /* Rounded corners */
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 500;
      color: black;
      cursor: pointer;
      transition: background 0.3s ease-in-out;
      flex-shrink: 0;
  }

  .global-admin-edit-button:hover {
      background-color: #bcbcbc;
  }

  .global-admin-icon {
      width: 16px;
      height: 16px;
  }

  .global-admin-discard-button {
      height: 45px;
      width: 15%;
      border-color: none;
      border-radius: 4px;
      border: 1px solid #bcbcbc;
      cursor: pointer;
      margin-left: 63%;
  }


  /* .header-role {
    margin-left: 16%;
} */

  .global-admin-apply-button {
      height: 45px;
      width: 15%;
      border-color: #C12328;
      border-radius: 4px;
      background-color: #C12328;
      color: white;
      cursor: pointer;

  }

  .globaldeleteIcon {
      width: 13px;
      height: 13px;
      margin-top: -4px;
      margin-right: 6px;

  }

  .globalApplyIcon {
      width: 13px;
      height: 13px;
      margin-top: -4px;
      margin-right: 6px;

  }

  .global-admin-image-upload-circle {
      cursor: pointer;
      width: 124px;
      height: 124px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f0f0f0;
      margin-left: -19%;

  }

  .globaldeleteIconRedColor {
      color: #C12328;
      width: 14px;
      height: 14px;
      margin-top: -3px;
      margin-right: 3px;
  }

  .global-admin-delete-icon-button {
      height: 45px;
      width: 26%;
      border-color: none;
      border-radius: 4px;
      border: 1px solid #C12328;
      cursor: pointer;
      background-color: white;
      margin-left: -36%;
  }

  .edit-cancel-and-apply {
      display: flex;
      gap: 12px;
      width: 100%;
      justify-content: end;
  }

  .modal-footer {
      margin-top: 24px;
      display: flex;
      align-items: center;
      margin-left: 26%;
  }

  .delete-modal-footer {
      margin-top: 24px;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 12px;
  }

  .global-admin-Delete-button {
      height: 45px;
      width: 30%;
      border-color: #C12328;
      border-radius: 4px;
      background-color: #C12328;
      color: white;
      cursor: pointer;
  }


  .global-admin-Cancel-button {
      height: 45px;
      width: 30%;
      border-color: none;
      border-radius: 4px;
      border: 1px solid #bcbcbc;
      cursor: pointer;
  }

  .searchIconGlobalAdmin {
      font-size: 12px;
      height: 19%;
      width: 10%;
      margin-left: 87%;
      margin-top: -22%;
  }


  .global-admin-pagination-controls {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;
  }

  .global-admin-pagination-controls button {
      /* background-color: #C12328; */
      /* color: white; */
      border: none;
      padding: 8px 16px;
      border-radius: 5px;
      /* font-size: 14px; */
      cursor: pointer;
      transition: background-color 0.3s ease;
  }

  .pagination-button:disabled {
      color: #ccc;
      cursor: not-allowed;
  }

  .pagination-button:hover {
      font-weight: 700;
  }

  .user-profile-image {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: contain;
  }

  @media screen and (min-device-width: 4px) and (max-device-width: 480px) {
      .search-container-global-admin {
          height: 45px;
          font-size: 14px;
          margin-left: -47%;
          padding: 36px;
          margin-top: 9%;
      }

      .global-admin-discard-button {
          height: 45px;
          width: 31%;
          border-color: none;
          border-radius: 4px;
          border: 1px solid #bcbcbc;
          cursor: pointer;
          margin-left: -1px;
      }


      .globaldeleteIconRedColor {
          display: none;
      }

      .globaldeleteIcon {
          display: none;
      }

      .globalApplyIcon {
          display: none;
      }

      .global-admin-apply-button {
          height: 45px;
          width: 31%;
          border-color: #C12328;
          border-radius: 4px;
          background-color: #C12328;
          color: white;
          cursor: pointer;
      }

      .searchIconGlobalAdmin {
          display: none;
      }

      .CreateIcon {
          display: none;
      }

      .search-box {
          width: 72%;
          padding: -7px 17px;
          padding-left: 40px;
          border: 1px solid #d1d5db;
          height: 38px;
          border-radius: 100px;
      }

      .global-admin-addNew-button {
          font-size: 12px;
          padding: 6px 10px;
      }

      .searchIconGlobalAdmin {
          display: none;
      }

      /* .user-list-header span {
        font-size: 12px;
        text-align: center;
    } */

      .user-card {
          padding: 10px;
      }

      .user-role-dropdown {
          width: 100%;
          margin-top: 3%;
          text-align: center;
          justify-content: center;
          display: flex;
          margin-bottom: 3%;
      }

      .header-content {
          flex-direction: column;
          align-items: center;
      }

      .user-card {
          flex-direction: column;
          align-items: center;
          text-align: center;
      }

      .global-admin-addNew-button {
          /* font-size: 14px; */
          /* padding: 11px 18px; */
          margin-left: 64%;
          /* width: 33%; */
          margin-top: -34px;

      }

      .user-title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          text-align: center;
      }

      .user-list-header {
          display: none;
      }

      .header-name,
      .header-role,
      .header-edit {
          margin-left: 0;
          display: block;
          width: 100%;
      }

      .global-admin-delete-icon-button {
          height: 45px;
          width: 29%;
          border-color: none;
          border-radius: 4px;
          border: 1px solid #C12328;
          cursor: pointer;
          background-color: white;
          margin-left: -36%;
      }
  }

  @media screen and (min-device-width: 481px) and (max-device-width: 768px) {
      .search-container-global-admin {
          height: 92px;
          width: 367%;
      }

      .user-list-header {
          display: grid;
          grid-template-columns: 2.6fr 3.8fr 2fr 1.2fr;
          padding: 10px;
          background: #f3f4f6;
          font-weight: bold;
          border-radius: 8px;
      }

      .modal-footer {
          margin-top: 24px;
          display: flex;
          /* align-items: center; */
          margin-left: 26%;
          gap: 20px;

      }


      .global-admin-discard-button {
          height: 45px;
          width: 20%;
          border-color: none;
          border-radius: 4px;
          border: 1px solid #bcbcbc;
          cursor: pointer;
          margin-left: 49%;
      }

      .global-admin-apply-button {
          height: 45px;
          width: 20%;
          border-color: #C12328;
          border-radius: 4px;
          background-color: #C12328;
          color: white;
          cursor: pointer;
      }

      .CreateIcon {
          display: none;
      }

      .header-content {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 55%;
          margin-top: 6%;
      }

      .searchIconGlobalAdmin {
          display: none;
      }

      .search-container-global-admin img {
          width: 8%;
          margin-left: 85%;
      }

      .global-admin-header {
          flex-direction: column;
          align-items: center;
      }

      .user-list-header {
          /* flex-direction: column;
        text-align: center; */
          font-size: 12px;
      }

      .header-name,
      .header-role,
      .header-edit {
          margin-left: 0;
          display: block;
          width: 100%;
      }

      .header-role {
          margin-left: 1%;
      }

      .user-card {
          /* flex-direction: column;
        align-items: center;
        text-align: center; */
          /* Stack user info vertically */
      }

      .global-admin-addNew-button {
          font-size: 17px;
          padding: 19px 20px;
          /* margin-left: 64%; */
          width: 252%;
          margin-top: 6px;
      }

      .user-role-dropdown {
          /* width: 100%; */
      }

      .global-admin-delete-icon-button {
          height: 45px;
          width: 21%;
          border-color: none;
          border-radius: 4px;
          border: 1px solid #C12328;
          cursor: pointer;
          background-color: white;
          margin-left: -35%;
      }
  }

  @media screen and (min-device-width: 769px) and (max-device-width: 1023px) {
      .search-container-global-admin {
          width: 80%;
          /* Reduces the width of the search input */
      }


      .user-list-header {
          display: grid;
          grid-template-columns: 0.9fr 1fr 0.2fr;
          padding: 10px;
          background: #f3f4f6;
          font-weight: bold;
          border-radius: 8px;
      }

      .searchIconGlobalAdmin {
          display: none;
      }

      .globaldeleteIconRedColor {
          display: none;
      }

      .search-container-global-admin img {
          margin-left: 75%;
          /* Adjusts the search icon's position */
      }

      .global-admin-discard-button {
          height: 45px;
          width: 20%;
          border-color: none;
          border-radius: 4px;
          border: 1px solid #bcbcbc;
          cursor: pointer;
          margin-left: 46%;
      }


      .global-admin-apply-button {
          height: 45px;
          width: 20%;
          border-color: #C12328;
          border-radius: 4px;
          background-color: #C12328;
          color: white;
          cursor: pointer;
      }


      .global-admin-delete-icon-button {
          height: 45px;
          width: 19%;
          border-color: none;
          border-radius: 4px;
          border: 1px solid #C12328;
          cursor: pointer;
          background-color: white;
          margin-left: -35%;
      }

      .global-admin-addNew-button {
          font-size: 14px;
          /* Adjusts the button font size */
          padding: 8px 12px;
          /* Adjusts button padding */
      }

      .header-content {
          gap: 30%;
          margin-top: 9%;
          /* Adjust the space between the search bar and the button */
      }

      .user-list-header {
          grid-template-columns: 2.9fr 3.1fr 2fr 1fr;
          padding: 8px;
          font-size: 14px;
      }

      .user-card {
          /* flex-direction: column;
        align-items: center;
        text-align: center; */
          /* Stack user info vertically */
      }

      .user-role {
          padding: 5px 10px;
          font-size: 14px;
      }

      .header-user {
          font-size: 12px;
      }
  }

  @media screen and (min-device-width: 1025px) and (max-device-width: 1280px) {
      .search-container-global-admin {
          /* width: 80%; */
      }

      .searchIconGlobalAdmin {
          display: none;
      }

      .search-container-global-admin img {
          margin-left: 75%;
      }

      /* .global-admin-addNew-button {
        font-size: 14px;
        padding: 8px 12px;
    } */

      .header-content {
          /* gap: 55%; */
          margin-top: -1%;
      }

      .user-list-header,
      .user-card {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
      }

      .user-list-header {
          font-weight: bold;
          background-color: #f5f5f5;
          padding: 10px;
          border-radius: 5px;
      }



      .user-role-dropdown {
          flex: 1.5;
      }

      .user-card {
          /* flex-direction: column;
        align-items: center;
        text-align: center; */
          /* Stack user info vertically */
      }

      .user-role {
          padding: 5px 10px;
          font-size: 14px;
      }

      .header-user {
          font-size: 12px;
      }
  }

  @media screen and (min-device-width: 1281px) and (max-device-width: 2560px) {
      .search-container-global-admin {
          width: 80%;
      }

      .searchIconGlobalAdmin {
          display: none;
      }

      .search-container-global-admin img {
          margin-left: 75%;
      }

      .global-admin-addNew-button {
          font-size: 14px;
          padding: 8px 12px;
          margin-left: -85%;
      }

      .header-content {
          gap: 102%;
          margin-top: 0%;
      }

      .user-list-header {
          display: grid;
          grid-template-columns: 9.4fr 8.2fr 6.3fr 1.7fr;
          padding: 10px;
          background-color: #f5f5f5;
          font-weight: bold;
      }

      .user-list-header span {
          flex: 1;
      }

      .user-card {
          /* flex-direction: column;
        align-items: center;
        text-align: center; */
          /* Stack user info vertically */
      }

      .user-role {
          padding: 5px 10px;
          font-size: 14px;
      }

      .header-user {
          font-size: 12px;
      }
  }