.upload-container {
    display: flex;
}

.create-client-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1%;
}

.inactive-button {
    background-color: #f3f3f3;
    color: #ffffff;
    cursor: not-allowed;
}

.active-button {
    background-color: #7F56D9;
    color: #ffffff;
    cursor: pointer;
}

/* .image-upload-circle {
    width: 80px;
    height: 80px;
    background-color: #f0e7ff;
    border: 2px solid #7f56d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.upload-icon {
    font-size: 24px;
    color: #7f56d9;
    width: 47%;
} */

.arrow-circle {
    width: 47%;

}

.uploaded-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
}

.create-client-button1,
.create-client-button2 {
    background-color: rgba(108, 63, 235, 1);
    color: white;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    width: 100px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    gap: 20px;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.create-client-button1:hover,
.create-client-button2:hover {
    background-color: rgba(88, 43, 215, 1);
}

.create-client-button1:active,
.create-client-button2:active {
    transform: scale(0.98);
}

/* Hide the RGBA/HSLA section */
div.flexbox-fix>div:nth-child(6) {
    display: none !important;
}

/* Hide the alpha slider */
.chrome-picker .alpha {
    display: none !important;
}

/* Optionally, hide the hex input to avoid showing the color code */
.chrome-picker .color-picker input[type="text"] {
    display: none !important;
}

/* Ensure only hue and saturation pickers are visible */
.chrome-picker .hue-picker,
.chrome-picker .saturation-picker {
    display: block !important;
}



.login-group {
    border: 1px solid #d9d9d9;
    margin-right: 2%;
    display: flex;
    padding-left: 5px;
    align-items: flex-start;
}

.registration-group {
    border: 1px solid #d9d9d9;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.registration-group .ant-checkbox-group {
    border: 1px solid #d9d9d9;
    padding: 5px;
}

.registration-group .ant-checkbox-wrapper {
    /* margin-bottom: 10px; */
    padding: 5px;
}


/* Responsive Styles */
@media (max-width: 768px) {
    .card-buttons {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }

    .create-client-button1,
    .create-client-button2 {
        width: 80%;
        height: auto;
        font-size: 12px;
    }
}

@media (max-width: 480px) {

    .create-client-button1,
    .create-client-button2 {
        width: 90%;
        font-size: 11px;
    }
}