.product-config-container {
    /* max-width: 600px; */
    margin: auto;
    padding: 20px;
    background-color: #f8f8f8;
}

.product-config-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: "Inter";
}

.product-config-card {
    background: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-config-option {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.product-config-option:hover {
    background-color: #f3f3f3;
}

.product-config-option input {
    margin-right: 10px;
}

.product-config-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 12px;
}

.product-config-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
}

.product-config-btn.back {
    background-color: white;
    border: 1px solid #ddd;
}

.product-config-btn.next {
    background-color: #d32f2f;
    color: white;
}

.product-config-btn.next:hover {
    background-color: #b71c1c;
}