/* Container */
.bulk-leads-container {
    padding: 20px;
    margin: auto;

}

/* Header */
.bulk-leads-header {
    text-align: left;
    margin-bottom: 20px;
}

/* Summary Cards */
.bulk-leads-cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.bulk-leads-card {
    display: flex;
    align-items: center;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    width: 30%;
    justify-content: space-between;
    /* Align items horizontally */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid black;
}

.bulk-leads-card img {
    width: 19px;
    height: 19px;
    margin-right: 10px;
    /* Space between image and text */
}

.bulk-leads-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* Makes text content flexible */
}

.bulk-leads-card-text {
    margin: 0;
    font-size: 20px;
    color: #666;
    font-family: Inter;
}

.bulk-leads-card-value {
    font-size: 20px;
    font-weight: bold;
    /* position: absolute; */
    right: 15px;
}

/* Info Sections */
.bulk-leads-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Moves the download button to the right */
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-top: 15px;
}



.bulk-leads-info-left {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adds space between the icon and text */
}

.bulk-leads-info p {
    color: #383838;
    font-size: 17px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    margin: 0;
}


.bulk-leads-info img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.bulk-leads-info-text {
    flex-grow: 1;
    font-weight: bold;
    margin: 0;
}

/* Buttons */
/* .bulkUploadUpdate, */
/* .bulkUploadDelete, */
.bulkUploadSubmit {
    padding: 6px 14px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
}

.bulkUploadUpdate {
    background: #c81e1e;
    color: white;
    border: none;
}

.bulkUploadDownload {
    color: #dc2626 !important;
    border: 1px solid #dc2626;
    background: transparent;
    padding: 6px 14px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
}

.bulkUploadDownload:hover {
    background: #a51717; /* Darker red on hover */
    color: white !important; /* Ensures no blue color */
    text-decoration: none; /* Ensures no underline */
}

.bulkUploadSubmit {
    background: #c81e1e;
    color: white;
    border: none;
}

/* Submit Button */
.bulk-leads-submit {
    text-align: right;
    margin-top: 20px;
}