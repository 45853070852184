.product-selection-container {
    text-align: center;
}


.view-role-toggle-switch {
    display: flex;
    position: relative;
    width: 393px;
    background: #e0e0e0;
    border-radius: 25px;
    padding: 25px;
    margin-bottom: 20px;
    align-items: center;
    height: 20px;
}

.search-container-view-role {
    display: flex;
    margin-left: 2%;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.product-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 14px;
}

.product-card {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    width: 200px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.product-card.active:hover {
    background-color: #FAC9CA;
    transform: scale(1.05);
}

.product-card:hover {
    transform: scale(1.05);
    background-color: #FAC9CA;
    cursor: pointer;
}

.product-card.selected {
    background-color: #FAC9CA;
    /* Change to desired active color */
    transform: scale(1.05);
}

.product-card.disabled {
    /* cursor: not-allowed; */
    opacity: 0.5;
    cursor: pointer;
}

.back-button {
    background-color: #C12328;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    transition: background-color 0.3s ease;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: end;
}

.product-card.disabled:hover {
    transform: none;

}

.product-logo {
    width: 100%;
    height: auto;
}

.product-name {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.product-tagline {
    font-size: 14px;
    color: gray;
    margin-top: 5px;
}

.user-management-container {
    padding: 20px;
}

.toggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-management-view-role-toggle-switch {
    display: flex;
    position: relative;
    width: 393px;
    background: #e0e0e0;
    border-radius: 25px;
    padding: 25px;
    margin-bottom: 20px;
    align-items: center;
}

.toggle-option {
    flex: 1 1;
    text-align: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.3s ease;
    position: relative;
    z-index: 2;
}

.toggle-option.active {
    color: white;
}

.user-management-view-role-toggle-switch[data-active="product-configuration"] .toggle-slider {
    transform: translateY(165px);
}

.toggle-slider {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 189px;
    height: 40px;
    background: #c92d2d;
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Adjust Slider Position */
.toggle-slider.user-management {
    left: 187px;
}

/* Smooth Transition for Content */
.tab-content {
    /* opacity: 0; */
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.tab-content.user-management,
.tab-content.product-configuration {
    opacity: 1;
    transform: translateY(0);
}

.toggle-slider.left {
    transform: translateX(0%);
}

.toggle-slider.right {
    transform: translateX(100%);
}

.view-role-edit-button {
    background-color: #C12328;
    color: white;
    padding: 8px 12px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    border-color: #C12328;
    margin-top: -2%;
    white-space: nowrap;
    width: 100px;
}


.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 18px;
    gap: 10px;
    /* margin-top: -19px; */
    margin-bottom: 1%;
}

.pagination-button {
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    cursor: pointer;
}

.pagination-text {
    font-size: 16px;
    color: #333;
    font-weight: 500;
}

.toggle-slider.product-configuration {
    left: 13px;
}

.search-add-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.add-user-button {
    background-color: #C12328;
    color: white;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    white-space: nowrap;
    margin-right: 19px;
}

.product-selection-breadcrumb {
    display: flex;
    padding: 0px 0px 0px 24px;
    gap: 7px;
    margin-bottom: 20px;
}