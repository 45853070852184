.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFEDED;
}

.reset-password-form {
    background-color: #F5F5F5;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 24%;
    /* max-width: 400px; */
    text-align: center;
}

.reset-password-subtitle {
    font-size: 13px;
    color: #333;
    margin-bottom: 30px;
    text-align: left;
    font-weight: 500;
}

.email-label {
    font-size: 13px;
    color: #333;
    display: block;
    margin-bottom: 10px;
    text-align: left;
}

.email-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 60px;
    background-color: #F5F5F5;
}

.email-input:focus {
    border-color: #007BFF;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
}

.continue-btn {
    width: 100%;
    padding: 12px;
    background-color: #C12328;
    color: white;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.continue-btn.active {
    background-color: #C12328;
}

.continue-btn.inactive {
    background-color: #F8D7DA;
}

.forget-password-error-message {
    color: #C12328;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    margin-top: -55px;
    margin-bottom: 20%;
}

.forget-password-success-message {
    color: black;
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
}

.logo {
    width: 100px;
    margin-bottom: 20px;
}

.title-container {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    margin-top: 10%;
}

.arrow-icon {
    width: 12px;
    height: 12px;
    margin-right: 10px;
}

.reset-password-title {
    font-size: 17px;
    font-weight: 500;
    color: #000;
    margin: 0;
}


@media (max-width: 1199px) {
    .reset-password-form {
        width: 50%;
    }
}

@media (max-width: 1099px) {
    .reset-password-form {
        width: 55%;
        background-color: white;

    }
}

@media (max-width: 999px) {
    .reset-password-form {
        width: 60%;
        background-color: white;

    }
}

@media (max-width: 899px) {
    .reset-password-form {
        width: 70%;
        background-color: white;

    }
}

@media (max-width: 799px) {
    .reset-password-form {
        width: 80%;
        background-color: white;

    }
}

@media (max-width: 699px) {
    .reset-password-form {
        width: 90%;
        background-color: white;

    }
}

@media (max-width: 599px) {
    .reset-password-form {
        width: 100%;
        padding: 20px;
        margin: 20px;
        background-color: white;
    }

}