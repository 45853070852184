/* General Styles */
.dashboard-container {
    padding: 63px;
    background-color: #f5f5f5;
    margin-top: -70px;
}

/* Header */
.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.user-info img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.user-details p {
    margin: 0;
}

.user-name {
    font-weight: bold;
}

.user-role {
    font-size: 14px;
    color: gray;
}

/* Overview Cards */
.overview-cards {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 16px;
    margin-top: 24px;
}

.super-manager-card-title {
    font-size: 18px;
    /* font-weight: bold; */
}

.super-manager-card-subtitle {
    color: gray;
    font-family: Inter;
}

.super-manager-card-value {
    font-size: 24px;
    font-family: Inter;
    text-align: center;
    font-weight: 500;

}

/* Stats Section */
/* Stats Component Styles */
.stats-container {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    /* background-color: white;
    padding: 8px 16px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    margin-top: 2%;
    margin-bottom: 2%; */

    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 0px -40px 0px -1px;
}


.stats-left {
    flex: 0 0 300px;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.stats-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.stats-item-client {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 15px;
}

.stats-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.stats-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 5px;
    font-family: Inter;
}

.stats-label {
    font-size: 18px;
    color: #000000;
    font-family: Inter;
}

.stats-client {
    font-size: 27px;
    color: #000000;
    /* margin-bottom: 2px; */
    font-family: Inter;
    font-weight: 600;
}

.stats-value-client {
    font-size: 30px;
    font-weight: 600;
    text-align: right;
    margin-left: auto;
    color: #dc2626;
}

.stats-value {
    font-size: 18px;
    font-weight: 600;
    text-align: right;
    margin-left: auto;
    color: #dc2626;
}

.super-manager-image {
    width: 25px;
    height: 25px;
}

.super-manager-image-client {
    width: 40px;
    height: 40px;
}

.stats-right {
    flex: 1;
    display: flex;
    gap: 16px;
    min-width: 0;
}


.product-card-dashboard {
    flex: 1;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    min-width: 0;
    text-align: center;
}

.product-card-dashboard.hovered {
    background-color: #dc2626;
    color: #fff;
    cursor: pointer;
}

.product-card-dashboard.hovered .product-label,
.product-card-dashboard.hovered .product-value {
    color: white;
}

.product-logo {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    letter-spacing: 1px;
}

.product-logo img {
    width: 60%;
    /* Set fixed width */
    height: auto;
    /* Maintain aspect ratio */
    transition: opacity 0.3s ease-in-out;
}

.product-stats {
    display: flex;
    margin-top: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.product-label {
    font-size: 16px;
    color: #777;
    margin-bottom: 4px;
    font-family: Inter;
}

/* .product-label:hover {
    color: white;
    font-size: 16px;
    color: #777;
    margin-bottom: 4px;
    font-family: Inter;
} */

.product-value {
    font-size: 32px;
    font-weight: 600;
    font-family: Inter;
}

/* .product-value:hover {
    color: white;
    font-size: 32px;
    font-weight: 600;
    font-family: Inter;
} */


.cards-container-dashboard {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 11px;
    padding: -17px;
    margin: 0px -40px 0px -1px;
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .stats-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        margin-bottom: 2%;
    }
}

/* Clients Section */
/* Client List Component Styles */
.clients-wrapper {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 16px;
}

.clients-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 16px 0;
    color: #111827;
}

.search-sort-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 10px;
}

.super-manager-search-container {
    position: relative;
    flex-grow: 0.9;
}

.search-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    font-size: 14px;
}

.search-button {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
}

.sort-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.sort-label {
    font-size: 14px;
    color: #6b7280;
}

.sort-select {
    padding: 8px 145px 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    background-color: white;
    font-size: 14px;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%236b7280' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
}

.client-list {
    /* border: 1px solid #e5e7eb; */
    border-radius: 4px;
}

.client-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    /* border-bottom: 1px solid #e5e7eb; */
}

.client-item:last-child {
    border-bottom: none;
}

.client-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.chevron-icon {
    color: #6b7280;
    font-size: 14px;
}

.client-icon {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-name {
    font-weight: 500;
    font-size: 15px;
}

.client-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}


.stats-badge {
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 160px;
    gap: 6px;
    font-size: 14px;
    color: #6b7280;
}

.badge-icon {
    color: #dc2626;
    font-size: 14px;
}

.badge-value {
    font-weight: 500;
    color: #111827;
    text-align: right;
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .search-sort-container {
        flex-direction: column;
    }

    .client-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .client-right {
        width: 100%;
        justify-content: flex-end;
    }
}


/* .overview-cards {
    display: flex;
    gap: 20px;
} */

.overview-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 250px; */
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* 
.overview-left {
    flex: 1;
}

.overview-right {
    flex: 1;
    text-align: right;
} */

.super-manager-card-subtitle {
    font-size: 12px;
    color: gray;
}

.super-manager-super-manager-card-value {
    font-size: 18px;
    font-weight: bold;
    font-family: Inter;
}


/* Client Dropdown with Cards CSS */
.clients-wrapper {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 16px;
}

.clients-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 16px 0;
    color: #111827;
}

.search-sort-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 10px;
}

.search-container {
    position: relative;
    flex-grow: 1;
}

.search-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    font-size: 14px;
}

.search-button {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
}

.sort-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.sort-label {
    font-size: 14px;
    color: #6b7280;
}

.sort-select {
    padding: 8px 100px 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    background-color: white;
    font-size: 14px;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%236b7280' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
}

.client-list {
    /* border: 1px solid #e5e7eb; */
    border-radius: 4px;
}

.client-wrapper {
    border-bottom: 1px solid #e5e7eb;
}

.client-wrapper:last-child {
    border-bottom: none;
}

.client-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
}

.client-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.chevron-icon {
    color: #6b7280;
    font-size: 14px;
    transition: transform 0.3s ease;
}

.client-icon {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #dc2626;
}

.client-name {
    font-weight: 500;
    font-size: 15px;
}

/* .client-right {
    display: flex;
    gap: 100px;
} */

/* .stats-badge {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    color: #6b7280;
} */

.badge-icon {
    color: #dc2626;
    font-size: 14px;
    width: 16px;
    height: 16px;
}

/* .badge-value {
    font-weight: 500;
    color: #111827;
    margin-left: 2px;
} */

/* Expanded section styles */
.client-expanded {
    /* background-color: #fef2f2; */
    padding: 16px;
    animation: fadeIn 0.3s ease;
    gap: 16px;
    /* width: 50%; */
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.super-manager-cards-container {
    width: 48%;
    /* Adjust as needed to create proper spacing */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.cards-row {
    display: flex;
    justify-content: space-between;
}

.super-manager-role-card {
    flex: 1 1;
    background-color: #fff1f1;
    padding: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-right: 1px solid #fee2e2;
}

.super-manager-role-card,
.app-card {
    flex: 1;
    background-color: #fff1f1;
    padding: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-right: 1px solid #fee2e2;
}

.super-manager-role-card:last-child,
.app-card:last-child {
    border-right: none;
}

.super-manager-card-title {
    font-size: 14px;
    font-family: Inter;
    /* color: #6b7280; */
}

.app-logo {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #4b5563;
}

.card-count {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    color: #111827;
    font-weight: 700;
}

.user-icon {
    width: 14px;
    height: 14px;
    color: #6b7280;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .search-sort-container {
        flex-direction: column;
    }

    .client-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .client-right {
        width: 100%;
        justify-content: flex-end;
    }

    .cards-row {
        flex-direction: column;
    }

    .super-manager-role-card,
    .app-card {
        border-right: none;
        border-bottom: 1px solid #fee2e2;
        padding: 16px 8px;
    }

    .super-manager-role-card:last-child,
    .app-card:last-child {
        border-bottom: none;
    }
}

.app-image {
    height: 21px;
    width: 81px;
}

.user-icon {
    height: 18px;
    width: 18px;
}

.client-logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 5px;
    background: #b4b9c6;
}


/* Add to your superManagerDashboard.css file */

.more-clients-container {
    /* display: flex;
    justify-content: center; */
    margin: 20px 0px;
}

.more-clients-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #555;
    border: 1px solid #dc2626;
    border-radius: 25px;
    padding: 8px 25px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 160px;
}

.more-clients-btn:hover {
    background-color: #fff0f0;
}

.moreClientsImage {
    width: 20px;
    height: 20px;
    margin-left: 8px;
}


@media screen and (min-device-width: 1280px) and (max-device-width: 1300px) and (min-height: 1920px) and (max-height: 2000px) {
    .dashboard-container {
        /* padding: 63px;
        background-color: #f5f5f5; */
        margin-top: -70px;
    }
}

@media screen and (min-device-width: 1372px) and (max-device-width: 1400px) {
    .dashboard-container {
        /* padding: 63px;
        background-color: #f5f5f5; */
        margin-top: -36px;
    }
}

@media screen and (max-device-width: 1280px) {
    .dashboard-container {
        /* padding: 63px;
        background-color: #f5f5f5; */
        /* margin-top: 50px; */
    }
}

/* For small screens (mobile devices) */
@media (max-width: 640px) {
    .dashboard-container {
        padding: 20px;
    }

    .dashboard-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .overview-cards {
        grid-template-columns: 1fr;
        /* Stack cards vertically */
    }

    .stats-container {
        flex-direction: column;
    }

    .stats-left {
        width: 100%;
    }

    .stats-right {
        flex-direction: column;
        width: 100%;
    }

    .search-sort-container {
        flex-direction: column;
    }

    .client-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .client-right {
        width: 100%;
        justify-content: flex-end;
    }

    .client-expanded {
        flex-direction: column;
        gap: 10px;
    }
}

/* For tablets (medium screens) */
@media (max-width: 1024px) {
    .overview-cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .cards-container-dashboard {
        grid-template-columns: repeat(1, 2fr);
        margin-left: -15px;
    }

    .stats-container {
        flex-direction: column;
    }

    .stats-left {
        width: 100%;
    }

    .stats-right {
        flex-direction: column;
        width: 100%;
    }
}

/* For desktops (large screens) */
@media (min-width: 1025px) {
    .overview-cards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 3070px) and (max-width:4000px) {
    .overview-cards {
        grid-template-columns: repeat(4, 1fr);
    }

    .cards-container-dashboard {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
        padding: -17px;
        margin: 0px -46px 0px -75px;
    }
}