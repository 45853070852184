.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #FDEDED;
}

.illustration-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px;
  transition: opacity 0.3s ease-in-out;
}

.illustration {
  max-width: 74%;
  height: auto;
  margin-left: -12%;
}

.wrapper-container {
  display: flex;
  flex-direction: row;
  padding: 0%;
  width: 84%;
  background: #F5F5F5;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 1200px;
  transition: width 0.3s ease-in-out, flex-direction 0.3s ease-in-out;
}

.icon2 {
  width: 135px;
  height: auto;
  margin-top: 13px;
  margin-bottom: -11px;
  margin-left: 33%;
}

.login-form {
  margin-left: 7%;
}

.border {
  border: 2px solid #d7d7d7;
  border-radius: 10px;
  height: auto;
  width: 71%;
  margin-left: 10%;
}

.form-container {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 443px;
  margin-left: -15%;
}

.welcome-text {
  font-size: 23px;
  font-weight: bold;
  height: 5%;
  text-align: center;
  margin-top: 20px;
}

.form-group {
  margin-right: 40%;
  margin-bottom: 6%;
}

.loginLable {
  font-size: 1rem;
  margin-bottom: 8px;
  display: block;
}

.form-input {
  width: 100%;
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 5px;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  color: white;
  font-size: 1.2rem;
  border: none;
  /* border-radius: 8px; */
  transition: background-color 0.3s, border-radius 0.3s;
}

.submit-btn:hover {
  border-radius: 20px;
  background-color: #C12328;
  /* Optional: Change the background color on hover */
}

.submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.forgot-password {
  margin-top: 10px;
  font-size: 1rem;
  text-decoration: none;
  margin-left: 31%;
}

.forgot-password:hover {
  text-decoration: none;
}

.submit-container {
  margin-top: 20px;
}

.submit-btn {
  width: 93%;
  padding: 8px;
  font-size: 1.2rem;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s, border-radius 0.3s;
  background-color: #C12328;
  color: #ffffff;
  margin-left: 0%;
  cursor: pointer;
}

.submit-btn.active {
  cursor: pointer;
  background-color: #6c63ff;
}

.submit-btn.active:hover {
  background-color: #5548c8;
}

.submit-btn.inactive {
  background-color: #cccccc;
  cursor: not-allowed;
}

.forget-password {
  font-size: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  color: #000000;
  margin-left: -7%;
}

.forget-password-click-here {
  color: #C12328;
  cursor: pointer;
}

.password-field-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: -70px;
  top: 57%;
  transform: translateY(-50%);
  cursor: pointer;
}

.emailandpassword-error-message {
  color: #AD0F14;
  font-size: 10px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  display: flex;
}

.message.success {
  color: green;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.backend-error-message {
  color: #AD0F14;
  font-size: 10px;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .wrapper-container {
    flex-direction: column;
  }

  .illustration-container,
  .form-container {
    flex: 1 1 100%;
  }

  .welcome-text {
    font-size: 24px;
    text-align: center;
  }
}

@media (min-width: 1026px) and (max-width: 1199px) {
  .illustration {
    max-width: 56%;
  }

  .wrapper-container {
    width: 100%;
    overflow: hidden;
    flex-direction: row;
  }

  .border {
    background: white;
    border: 2px solid #d7d7d7;
    border-radius: 10px;
    height: auto;
  }

  .illustration-container,
  .form-container {
    flex: 1 1 100%;
  }

  .form-container {
    padding: 20px;
  }

  .welcome-text {
    font-size: 24px;
    text-align: center;
  }
}



@media (max-width: 1024px) {

  .illustration {
    max-width: 56%;
  }

  .wrapper-container {
    width: 100%;
    overflow: hidden;
    flex-direction: row;
  }

  .border {
    background: white;
    border: 2px solid #d7d7d7;
    border-radius: 10px;
    height: auto;
    /* width: 120%;
    margin-left: 107%; */
  }

  .illustration-container,
  .form-container {
    flex: 1 1 100%;
  }

  .form-container {
    padding: 20px;

  }

  .welcome-text {
    font-size: 24px;
    text-align: center;

  }
}

@media (max-width: 768px) {

  .illustration-container {
    opacity: 0;
    pointer-events: none;
  }

  .form-container {
    padding: 20px;

  }

  .welcome-text {
    font-size: 24px;
    text-align: center;

  }

  .illustration {
    display: none;
  }

  .wrapper-container {
    width: 100%;
    overflow: hidden;
    background: none;
    box-shadow: none;
    border: none;
    flex-direction: column;
  }

  .border {
    background: white;
    border: 2px solid #d7d7d7;
    border-radius: 10px;
    height: auto;
    width: 120%;
    margin-left: 107%;
  }

}

@media (max-width: 480px) {
  .wrapper-container {
    width: 100%;
    overflow: hidden;
    background: none;
    box-shadow: none;
    border: none;
  }

  .form-container {
    margin: 9px;
    margin-left: -38px;
  }

  .border {
    background: white;
    border: 2px solid #d7d7d7;
    border-radius: 10px;
    height: auto;
    width: 92%;
    margin-left: 14%;
  }

  .submit-btn {
    font-size: 1rem;
  }

  .illustration {
    display: none;
  }

  .welcome-text {
    font-size: 24px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
}