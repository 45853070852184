.global-admin-image-upload-circle {
    cursor: pointer;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    margin-left: -19%;

}


.header-container {
    padding: 0 20px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 3;
    height: 15%;
}

.reusable-logo-container img {
    height: 40px;
}


.profile-dropdown {
    position: relative;
    width: 221px;
    /* transition: all 0.3s ease-in-out; */
    border: 1px solid black;
    border-radius: 12px;
    background: white;
    margin-right: 0.2%;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}



.profile-dropdown.open {
    background: #C12328;
    border-color: #C12328;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.profile-button {
    cursor: pointer;
    padding: 0px 10px;
    /* transition: all 0.3s ease-in-out; */
    height: 49px;
    width: 100%;
    border-radius: 11px;
}

.dropdown-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    min-height: 40px;
}

.profile-content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.profile-name {
    font-size: 18px;
    /* Adjust the font size */
    font-weight: bold;
    margin: 0;
    font-family: Inter;
    /* Space between name and designation */
}

.profile-designation {
    font-size: 14px;
    /* Smaller font size for designation */
    font-weight: normal;
    /* color: gray; */
    /* Optional: Differentiating color */
    margin: 0;
    font-family: Inter;
    /* Reset margin to avoid unnecessary spacing */
}


.profile-dropdown.open .profile-name {
    color: white;
}

.profile-dropdown.open .profile-designation {
    color: white !important;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
    border: 2px solid #FFFFFF;
}

.header-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
}

.logout-button {
    display: flex;
    align-items: center;
    gap: 10px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-family: Inter;
}

.profile-edit-button {
    display: flex;
    align-items: center;
    gap: 15px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-family: Inter;
    /* background-color: #C12328; */
}

.actions-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 95%;
    /* left: -0.1vh;
    right: -0.1vh; */
    gap: 10px;
    background: #C12328;
    border: 1px solid #C12328;
    border-top: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    /* overflow: visible; */
    height: 0;
    opacity: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;


    flex-direction: column;
    height: auto;
    /* Ensure it expands dynamically */
    min-height: 80px;
}

.logout-container,
.edit-container {
    width: 100%;
    /* Ensures full width */
    display: flex;
    /* justify-content: center; */
}

.profile-edit-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    filter: brightness(0) invert(1);
}

.profile-dropdown-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-dropdown.open .actions-container {
    height: 45px;
    opacity: 1;
    padding: 12px 9px;
    pointer-events: all;
    left: -0.9px;
    right: -0.5px;
}

.logout-icon {
    font-size: 23px;
    /* margin-left: 2%; */
}

.profile-button:hover {
    background-color: #C12328;
    border-color: #C12328;
    color: white;
}


.profile-button:hover .profile-name {
    color: white;
}

.profile-button:hover .profile-image {
    border-color: white;
}

.profile-button:hover .header-icon {
    content: none;
}

.profile-button:not(:hover) .header-icon {
    content: none;
}

.profile-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    min-height: 46px;
}

.mobile-logout-button {
    color: red;
    font-weight: bold;
}

.menu-icon {
    width: 24px;
    height: 24px;
    color: #6C3FEB;
}

.mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0px;
    /* Change from left to right */
    width: 250px;
    height: 100%;
    background: white;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    /* Start hidden off-screen to the right */
    opacity: 0;
    /* Make it invisible initially */
    transition: transform 0.5s ease, opacity 0.5s ease;
    /* Smooth transition */
    z-index: 100;
}

.mobile-menu.active {
    transform: translateX(0);
    opacity: 1;
}

.mobile-logout-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: none;
    border: none;
    color: #C12328;
    cursor: pointer;
    font-size: 19px;
    font-weight: 600;
    margin-top: 20%;
    margin-left: 16%;
    font-family: Inter;
}

.mobile-menu-item {
    padding: 10px;
    background-color: #fff;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    margin-top: 1rem;
}

.mobile-menu-item:hover {
    background-color: #f5f5f5;
}

.mobile-menu-item {
    display: flex;
    justify-content: end;
    align-items: end;
    padding: 10px;
    background: none;
    border: none;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    text-align: right;
    margin-left: -9%;
}

.mobile-menu-item:hover,
.mobile-logout-button:hover {
    background-color: #f5f5f5;
}

.mobile-menu-header {
    display: flex;
    /* margin-bottom: 20px; */
    margin-top: 20%;
    margin-left: 61%;
}

.menu-close-icon {
    cursor: pointer;
    margin-left: 4%;
    margin-top: -1px;
}

.mobile-profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: end;
    padding: 1.5rem 0;
}

.mobile-profile-info .profile-image {
    width: 3rem;
    height: 3rem;
    /* margin-bottom: 0.75rem; */
    border-radius: 50%;
}

.mobile-profile-info .text-container {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.is-mobile-profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: -63%;
}

.is-mobile-text-container {
    display: flex;
    flex-direction: column;
    margin-left: 20%;
}

.is-mobile-profile-name {
    font-size: 20px;
    font-family: 'Inter';
    font-weight: bold;
}

.is-mobile-profile-designation {
    font-size: 16px;
    color: gray;
    font-family: 'Inter';

}


@media screen and (max-width: 768px) {
    .mobile-menu-button {
        display: block;
    }

    .mobile-menu {
        display: block;
    }

    .profile-dropdown {
        display: none;
    }

    .header-container {
        padding: 0 20px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 3;
        height: 10%;
    }

    .reusable-logo-container img {
        height: 26px;
    }

}

@media screen and (max-width: 1279px) {
  

    .header-container {
        padding: 0 20px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 3;
        height: 10%;
    }

}

@media screen and (min-width: 1290px) and (max-width: 1372px) {
  

    .header-container {
        padding: 0 20px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 3;
        height: 10%;
    }

}