.readOnlyDate_field {
    width: 155%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #A7A7A7;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
}

.readOnlyDate_field::placeholder {
    color: #A7A7A7;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
}

.readOnlyDate_field:focus {
    outline: none;
    border-color: #ccc;
}

.readOnlyDate_field.valid-border {
    border: 1.8px solid #0EAC78;
}

.readOnlyDate_field.is-invalid {
    border-color: #AD0F14;
}

.email-error-message {
    color: #AD0F14;
    font-size: 10px;
    margin-top: 5px;
    font-weight: bold;
}