/* Container */
.calendar-container {
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 28rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Header */
  .calendar-header {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  
  /* Button Styling */
  .compliance-report {
    background-color: #dc2626;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    color: #ffffff;
    margin-right: 0.75rem;
  }
  
  .performance-report {
    background-color: #d1d5db;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    color: #4b5563;
  }
  
  /* Month Selector */
  .month-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .arrow-icon {
    cursor: pointer;
    color: #4b5563;
  }
  
  .month-name {
    font-size: 1rem;
    font-weight: 500;
  }
  
  /* Calendar Grid */
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.75rem;
    text-align: center;
  }
  
  .weekday {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .day-item {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ef4444;
    border-radius: 9999px;
    color: #ef4444;
    cursor: pointer;
  }
  
  .day-item:hover {
    background-color: #fef2f2;
  }
  
  /* Legend */
  .calendar-legend {
    margin-top: 1.25rem;
    font-size: 0.875rem;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .status-dot {
    width: 1rem;
    height: 1rem;
    border-radius: 9999px;
    display: inline-block;
  }
  
  .green {
    background-color: #10b981;
  }
  
  .red {
    background-color: #ef4444;
  }
  
  .orange {
    background-color: #f97316;
  }
  
  .blue {
    background-color: #3b82f6;
  }
  
  .yellow {
    background-color: #facc15;
  }
  
  /* Work Logs Button */
  .work-logs-button {
    width: 100%;
    background-color: #dc2626;
    color: #ffffff;
    padding: 0.5rem;
    border-radius: 0.375rem;
    margin-top: 1.25rem;
    cursor: pointer;
  }
  
  .work-logs-button:hover {
    background-color: #b91c1c;
  }
  