.user-management-container {
    display: block !important;
    visibility: visible !important;
}

.search-card {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.user-management-back-button {
    /* position: absolute; */
    /* right: 0px; */
    color: #d32f2f;
    border: 1px solid #d32f2f;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.search-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.search-group label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}

.search-input-wrapper {
    display: flex;
    gap: 10px;
}

.search-input {
    flex: 2;
}

.date-picker {
    flex: 1;
    min-width: 150px;
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add-user-btn {
    background-color: #d32f2f;
    border-color: #d32f2f;
    color: white;
    width: 150px;
    height: 40px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;

}

.add-user-btn:hover {
    background-color: #b71c1c;
    border-color: #b71c1c;
}

.select-user-btn {
    border: 1px solid #ccc;
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.select-user-icon {
    margin-right: 5px;
}

.button-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.back-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
}

.toggle-container {
    position: relative;
    /* Ensures the button can be positioned */
    display: flex;
    justify-content: center;
    /* Center the toggle */
    align-items: center;
    padding: 10px;
}

.custom-table-header {
    background-color: #FAC9CA !important;
    /* Change this to your preferred color */
    color: black !important;
    font-weight: bold;
    text-align: center;
}


.user-management-edit-button {
    display: flex;
    align-items: center;
    gap: 1px;
    background-color: #FAC9CA;
    border: none;
    border-radius: 20px;
    padding: 0px 7px;
    font-size: 14px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    transition: background 0.3sease-in-out;
    flex-shrink: 0;
}

.global-admin-add-user-button {
    color: #d32f2f;
    width: 150px;
    height: 45px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #d32f2f;
    /* margin-left: 20%; */
}

.user-management-cancel-button {
    height: 45px;
    width: 15%;
    border-color: none;
    border-radius: 4px;
    border: 1px solid #bcbcbc;
    cursor: pointer;
    /* margin-left: 63%; */
}

.global-admin-bulk-upload-button {
    color: white;
    width: 150px;
    height: 45px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid white;
    background-color: #439EFF;
    /* margin-right: 42%; */
}



.user-management-container-selectedUser {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    padding: 8px 16px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selection-controls {
    display: flex;
    align-items: center;
}

.select-button {
    padding: 6px 14px;
    background: white;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: background 0.2s, border-color 0.2s;
}

.select-button:hover {
    background: #f0f0f0;
}

.user-management-action-buttons {
    display: flex;
    gap: 10px;
    margin-left: auto;
    /* Align to the right */
}

.user-management-delete-button.disabled {
    background-color: #fae6e6;
    color: #dc2626;
    opacity: 0.5;
    cursor: not-allowed;
}

.user-management-delete-button {
    background-color: white;
    color: #dc2626;
    padding: 6px 14px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s, opacity 0.2s;
    border: 1px solid #dc2626;
}

.done-button {
    padding: 6px 14px;
    background: #c81e1e;
    color: white;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
}

.done-button:hover {
    background: #a71a1a;
}







.modal-button {
    padding: 8px 14px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.cancel-button {
    background-color: #f0f0f0;
    color: #333;
    margin-right: 10px;
}

.confirm-button {
    background-color: #a71a1a;
    color: white;
    width: 20%;
}

.assignNow {
    padding: 6px 14px;
    background: #c81e1e;
    color: white;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
}

.assignManagerailPosition {
    padding: 6px 14px;
    background: #c81e1e;
    color: white;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
}

.nextButton {
    padding: 6px 14px;
    background: #c81e1e;
    color: white;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
}


/* Add this to your CSS file */
.required-field::placeholder {
    color: #666;
    /* Normal color for placeholder text */
}

/* Then create a custom class that overwrites the above */
.required-asterisk {
    position: relative;
}

.required-asterisk::after {
    content: '*';
    color: #ff4d4f;
    /* Red color for asterisk */
    position: absolute;
    right: 100px;
    /* Adjust this value as needed */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.required-label::after {
    content: " *";
    color: #c81e1e;
    /* font-weight: bold; */
    font-family: Inter;
}

.handleDownload {
    padding: 6px 14px;
    background: #c81e1e;
    color: white;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
    width: 150px;
    height: 45px;
    margin: 0px 5px;
}

/* Custom styles for DatePicker popup */
.ant-picker-dropdown {
    font-size: 12px;
    /* Reduce font size */
    padding: 5px;
    /* Reduce padding */
    width: 100px;
    /* Optionally set a custom width */
    height: 50%;
    /* Ensure it fits within content */
}

.ant-picker-cell-inner {
    font-size: 12px;
    /* Adjust date text size */
}


.ant-tabs-tab:hover {
    color: #c81e1e !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #c81e1e !important;
    /* Ensures the active tab remains red */
}

.ant-tabs-ink-bar {
    background-color: #c81e1e !important;
    /* Red underline for the active tab */
}